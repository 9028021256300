import { useSearchParams } from "next/navigation";
import { IUseSignIn } from "./types";
import { useEffect } from "react";
import { trackMessage } from "@portal/appInsights";
import { useSession } from "@portal/auth/client/SessionProvider";
import signIn from "@portal/auth/client/api/signIn";
import { ISignInOptions } from "@portal/auth/client/api/signIn/types";

const useSignIn: IUseSignIn = () => {
  const searchParams = useSearchParams();
  const { session } = useSession();

  trackMessage(`useSignIn ${session?.status}`);

  useEffect(() => {
    if (!searchParams?.get("error")) {
      if (session?.status === "unauthenticated") {
        const signInOptions = { authType: "webApp" } as ISignInOptions;
        let url = new URL(`${process.env.NEXT_PUBLIC_APPLICATION_BASE_URL}/`);
        try {
          if (searchParams?.get("callbackUrl")) {
            url = new URL(searchParams?.get("callbackUrl") as string);
          }
        } catch (e) {
          console.error("invalid callbackUrl", e);
        }

        const centerId = url.searchParams?.get("centerId") || url.searchParams?.get("centerid");

        if (centerId) {
          signInOptions.logonwithcenterid = centerId;
        }
        const extraParams = new URLSearchParams();
        if (searchParams) {
          for (const key of searchParams.keys()) {
            if (key !== "callbackUrl") {
              extraParams.append(key, searchParams?.get(key) as string);
              url.searchParams.append(key, searchParams?.get(key) as string);
            }
          }
        }

        signInOptions.callbackUrl = url.toString();
        trackMessage("Sign in with identity-server4");
        signIn(signInOptions);
      } else if (session?.status === "authenticated") {
        const callbackUrl = searchParams?.get("callbackUrl");
        if (callbackUrl) {
          let pathAndQuery = callbackUrl;
          if (callbackUrl.includes("http")) {
            const url = new URL(callbackUrl as string);
            pathAndQuery = `${url.pathname}${url.search}`;
          }
          window.location.href = `${process.env.NEXT_PUBLIC_APPLICATION_BASE_URL}${pathAndQuery}`;
          return;
        }
        window.location.href = `${process.env.NEXT_PUBLIC_APPLICATION_BASE_URL}/`;
      }
    }
  }, [session?.status, searchParams]);
};

export default useSignIn;
