"use client";

import useSignIn from "@portal/hooks/useSignIn";
import SignInPage from "@portal/components/pages/signInPage";
import { Suspense } from "react";
import PageSpinner from "@portal/components/molecules/v2/pageSpinner";

const SigninClientComponent = () => {
  useSignIn();

  return SignInPage({ signInPath: "/auth/signin" });
};

const SigninClientComponentWrapper = () => (
  <Suspense fallback={<PageSpinner />}>
    <SigninClientComponent />
  </Suspense>
);

export default SigninClientComponentWrapper;
